// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.contact-container h1 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px; /* Add space below the headings */
}

.contact-container h2 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px; /* Add space below the headings */
}

.contact-container p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px; /* Add space below the paragraphs */
}
`, "",{"version":3,"sources":["webpack://./src/components/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB,EAAE,mCAAmC;AAC5D","sourcesContent":[".contact-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n}\n\n.contact-container h1 {\n    font-size: 30px;\n    text-align: center;\n    margin-bottom: 20px; /* Add space below the headings */\n}\n\n.contact-container h2 {\n    font-size: 20px;\n    text-align: center;\n    margin-bottom: 20px; /* Add space below the headings */\n}\n\n.contact-container p {\n    font-size: 18px;\n    text-align: center;\n    margin-bottom: 10px; /* Add space below the paragraphs */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
