// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foodimage img {
    width: 100%;
    padding: 2px;
}

.menu-item a {
    text-decoration: none;
    color: black;
}

.item-title {
    
    display: flex;
    align-items: center;
    padding: 0 10% 0 0;
    background-color:  rgb(166, 255, 144)
}

`, "",{"version":3,"sources":["webpack://./src/components/MenuCategory.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB;AACJ","sourcesContent":[".foodimage img {\n    width: 100%;\n    padding: 2px;\n}\n\n.menu-item a {\n    text-decoration: none;\n    color: black;\n}\n\n.item-title {\n    \n    display: flex;\n    align-items: center;\n    padding: 0 10% 0 0;\n    background-color:  rgb(166, 255, 144)\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
