// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
    width: 100%; 
    padding: 2px;
    border-width: 2px;
    border-color: rgb(163, 130, 0);
    border-style: solid;
}

.foodimage {
    width: 100%;

}



.detailed-title {
    padding: 5px;
    background-color:  rgb(166, 255, 144);
}

.detailed-description {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-width: 10px;
    border: 1px solid rgb(66, 47, 40);
    border-radius: 0px 0px 15px 15px;
    
}

.detailed-description input[type="checkbox"] {
    transform: scale(2.0);
}

.detailed-description p{
    font-size: 25px;
}

.detailed-description h2{
    font-size: 25px;
}

.padded5 {
    padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/DetailedItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;;AAEf;;;;AAIA;IACI,YAAY;IACZ,qCAAqC;AACzC;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;IACjC,gCAAgC;;AAEpC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".menu-item {\n    width: 100%; \n    padding: 2px;\n    border-width: 2px;\n    border-color: rgb(163, 130, 0);\n    border-style: solid;\n}\n\n.foodimage {\n    width: 100%;\n\n}\n\n\n\n.detailed-title {\n    padding: 5px;\n    background-color:  rgb(166, 255, 144);\n}\n\n.detailed-description {\n    width: 95%;\n    margin-left: auto;\n    margin-right: auto;\n    border-width: 10px;\n    border: 1px solid rgb(66, 47, 40);\n    border-radius: 0px 0px 15px 15px;\n    \n}\n\n.detailed-description input[type=\"checkbox\"] {\n    transform: scale(2.0);\n}\n\n.detailed-description p{\n    font-size: 25px;\n}\n\n.detailed-description h2{\n    font-size: 25px;\n}\n\n.padded5 {\n    padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
