// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
    border-width: 2px;
    border-color: rgb(107, 20, 20);
    border-style: solid;
    text-align: left;
    min-height: 100px; /* Adjust as needed */
    min-width: 100px;
}

.item-title {
    padding: 2px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.foodimage {
    width: 100%;
    min-height: 100px;
    padding:2px;

}

.menu-item-price {
    text-align: left;
    width: 100%;
}

.menu-item-title {
    text-align: left;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuItem.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB,EAAE,qBAAqB;IACxC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,WAAW;;AAEf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".menu-item {\n    border-width: 2px;\n    border-color: rgb(107, 20, 20);\n    border-style: solid;\n    text-align: left;\n    min-height: 100px; /* Adjust as needed */\n    min-width: 100px;\n}\n\n.item-title {\n    padding: 2px;\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}\n\n.foodimage {\n    width: 100%;\n    min-height: 100px;\n    padding:2px;\n\n}\n\n.menu-item-price {\n    text-align: left;\n    width: 100%;\n}\n\n.menu-item-title {\n    text-align: left;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
